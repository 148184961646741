<template>
    <div class="switch-icon">
        <el-switch
            @click.native.prevent="changeswitch('switch')"
            :disabled="disabled"
            :width="42"
            v-model="switchStatus"
            active-color="#366AFF"
            inactive-color="#E2E2E2"
        >></el-switch>
        <span class="switch-open-icon" @click="changeswitch('open')" v-if="switchStatus">
            <i class="iconfont guoran-a-16-17"></i>
        </span>
        <span class="switch-close-icon" @click="changeswitch('close')" v-if="!switchStatus">
            <i class="arsenal_icon arsenalcuo1"></i>
        </span>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        switchStatus: {
            type: Boolean,
            default: false
        },
        // 是否是自动改变状态(如需要手动改变状态 , 为false)
        needAutomatic: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        switchStatus: {
            handler (n) {
                console.log(n, 'nnnnnnn');
            }
        }
    },
    methods: {
        changeswitch (datas) {
            console.log(datas);
            if (this.needAutomatic) {
                this.$emit('changeswitch', !this.switchStatus)
            } else {
                this.$emit('changeswitch', datas)
            }

        }
    }
}
</script>

<style scoped lang="less">
.switch-icon {
    width: 42px;
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    text-align: center;
    align-items: center;
    .el-switch {
        text-align: center;
    }

    .switch-open-icon {
        position: absolute;
        z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 55%;

        i {
            color: white;
            font-size: 16px;
        }
    }

    .switch-close-icon {
        position: absolute;
        z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        i {
            color: #6e6b7b;
            font-size: 14px;
            font-weight: 600;
        }
    }

    i {
        color: #366aff;
        font-size: 18px;
    }
}
</style>